import React, { useState, useEffect, useCallback, useContext } from 'react';
import InviteViewers from './InviteViewers';
import UnInviteViewers from './UnInviteViewers';
import { getActiveStreams } from '../../Components/ShowPublisher/DataProvider';
import ProgressLoader from '../../Components/Common/ProgressLoader';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import { fetchShowViewers } from '../../Service/show';

interface IInviteViewersProps {
  showId: string;
  teamAdmin: boolean;
  gotoCreateStream: any;
  usersGroupsFlag?: boolean;
}

const MangeUser = (props: IInviteViewersProps) => {
  const { showId, teamAdmin, usersGroupsFlag } = props;
  const currentUser = get(useContext(AuthContext), 'currentUser', {});
  const [viewerLoading, setViewerLoading] = useState(false);
  const [streamLoading, setStreamLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [activeStream, setActiveStream] = useState([]);
  const [selectedStreamIndex, setSelectedStreamIndex] = useState(0);

  const [usersList, setUsersList] = useState([] as any[]);
  const [invitedUsersList, setInvitedUsersList] = useState([] as any[]);
  const [unInvitedUsersList, setUnInvitedUsersList] = useState([] as any[]);

  const [groupsList, setGroupsList] = useState([] as any[]);
  const [invitedGroupsList, setInvitedGroupsList] = useState([] as any[]);
  const [unInvitedGroupsList, setUnInvitedGroupsList] = useState([] as any[]);

  //To fetch list of active streams
  const getAllActiveStream = async () => {
    try {
      setStreamLoading(true);
      const activeStream = await getActiveStreams(showId, get(currentUser, 'user.accessToken', ''));
      if (!!activeStream) {
        activeStream.sort((a: any, b: any) =>
          a.user_entered_stream_label.localeCompare(b.user_entered_stream_label),
        );
        setActiveStream(activeStream);
        if (usersGroupsFlag) {
          fetchShowGroupData();
        }
        fetchViewers();
      } else {
        props.gotoCreateStream();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setStreamLoading(false);
    }
  };

  //fetch list of users filtered by showID and show_viewer role
  const fetchViewers = async () => {
    try {
      const result = await fetchShowViewers(showId);
      setUsersList(result?.hits || []);
    } catch (err) {
      console.log(err);
    } finally {
      setViewerLoading(false);
    }
  };

  //fetch list of groups for show
  const fetchShowGroupData = async () => {
    try {
      const getShowGroupListData = {
        api: api.group.listShowGroup,
        queryParam: { show_id: showId },
      };
      setGroupLoading(true);
      const groupsList = await backend.fetch(
        getShowGroupListData,
        get(currentUser, 'user.accessToken', ''),
      );
      if (groupsList) {
        await Promise.all(
          groupsList.map(async (group: any, index: number) => {
            const result: any = await showGroupUsers(group);
            const filteredShowUsers = result?.filter(
              (viewers: any) => viewers.role_type === 'ShowViewer',
            );
            group.show_group_users = [...filteredShowUsers];
          }),
        );
        setGroupsList(groupsList);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGroupLoading(false);
    }
  };

  const showGroupUsers = async (group: any) => {
    try {
      const data = {
        api: api.group.listShowGroupUser,
        queryParam: { show_group_id: group.show_group_id },
      };

      const result = await backend.fetch(data, get(currentUser, 'user.accessToken', ''));
      if (result) {
        return result;
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllActiveStream();
  }, [showId]);

  //updating invited users and uninvited users
  useEffect(() => {
    const invitedUsers: any = [];
    const unInvitedUsers: any = [];
    const activeStreamObj: any = activeStream[selectedStreamIndex];
    if (activeStream.length > 0 && !!activeStreamObj && !!activeStreamObj.id) {
      usersList.map((item: any) => {
        if (!!item.invited_streams) {
          const index = item.invited_streams.findIndex(
            (item: any) => item.stream_id === activeStreamObj.id,
          );
          const individual_user_invitation: any = get(
            item.invited_streams[index],
            'individual_user_invitation',
            '',
          );
          const group_invitation: any = get(item, 'group_invitation', []);

          if (
            usersGroupsFlag
              ? index > -1 &&
                (individual_user_invitation === 'Invited' || group_invitation.length > 0)
              : index > -1
          ) {
            invitedUsers.push(item);
          } else {
            unInvitedUsers.push(item);
          }
        } else {
          unInvitedUsers.push(item);
        }
        return null;
      });
    }

    setInvitedUsersList(invitedUsers);
    setUnInvitedUsersList(unInvitedUsers);
  }, [usersList, activeStream, selectedStreamIndex]);

  //updating invited users and uninvited users
  useEffect(() => {
    const invitedGroups: any = [];
    const unInvitedGroups: any = [];

    groupsList.forEach((group: any) => {
      let groupInvited = true;
      group.show_group_users.forEach((groupUser: any) => {
        const userIndex: number = usersList.findIndex(
          (user: any) => user.user_id === groupUser.user_id,
        );
        const streams = get(usersList[userIndex], 'invited_streams', []);
        const streamIndex = streams.findIndex(
          (stream: any) => stream.stream_id === get(activeStream[selectedStreamIndex], 'id', ''),
        );
        if (get(streams[streamIndex], 'group_invitation', []).includes(group.show_group_id)) {
          groupInvited = groupInvited && true;
        } else {
          groupInvited = groupInvited && false;
        }
      });
      if (groupInvited && get(group, 'show_group_users', []).length > 0) {
        invitedGroups.push(group);
      } else {
        unInvitedGroups.push(group);
      }
    });

    setUnInvitedGroupsList(unInvitedGroups);
    setInvitedGroupsList(invitedGroups);
  }, [groupsList, activeStream, selectedStreamIndex]);

  const refreshUninviteList = useCallback(
    (newInvitedUsers: any) => {
      if (!!newInvitedUsers && !!newInvitedUsers.length && newInvitedUsers.length > 0) {
        const newInvitedUsersList = newInvitedUsers.map((item: any) => item.user_id);
        const unInvitedUsersListArr = unInvitedUsersList.filter(
          (item: any) => !newInvitedUsersList.includes(item.user_id),
        );
        newInvitedUsers.forEach((users: any) => {
          invitedUsersList.push(users);
        });
        setInvitedUsersList(invitedUsersList);
        setUnInvitedUsersList(unInvitedUsersListArr);
      }
    },
    [invitedUsersList, unInvitedUsersList],
  );

  const refreshUninviteGroupList = useCallback(
    (newInvitedGroups: any) => {
      if (!!newInvitedGroups && !!newInvitedGroups.length && newInvitedGroups.length > 0) {
        const newInvitedGroupsList = newInvitedGroups.map((group: any) => group.show_group_id);

        const unInvitedGroupsListArr = unInvitedGroupsList.filter(
          (group: any) => !newInvitedGroupsList.includes(group.show_group_id),
        );

        const invitedGroupsListArr = [...invitedGroupsList];

        newInvitedGroups.forEach((group: any) => invitedGroupsListArr.push(group));

        setInvitedGroupsList(invitedGroupsListArr);
        setUnInvitedGroupsList(unInvitedGroupsListArr);
      }
    },
    [invitedGroupsList, unInvitedGroupsList],
  );

  const refreshInviteList = useCallback(
    (newUnInvitedUsers: any) => {
      if (!!newUnInvitedUsers && !!newUnInvitedUsers.length && newUnInvitedUsers.length > 0) {
        const newUnInvitedUsersList = newUnInvitedUsers.map((item: any) => item.user_id);
        const invitedUsersListArr = invitedUsersList.filter(
          (item: any) => !newUnInvitedUsersList.includes(item.user_id),
        );
        newUnInvitedUsers.forEach((users: any) => {
          unInvitedUsersList.push(users);
        });
        setUnInvitedUsersList(unInvitedUsersList);
        setInvitedUsersList(invitedUsersListArr);
      }
    },
    [invitedUsersList, unInvitedUsersList],
  );

  const refreshInviteGroupList = useCallback(
    (newUnInvitedGroups: any) => {
      if (!!newUnInvitedGroups && !!newUnInvitedGroups.length && newUnInvitedGroups.length > 0) {
        const newUnInvitedGroupsList = newUnInvitedGroups.map((group: any) => group.show_group_id);

        const invitedGroupsListArr = invitedGroupsList.filter(
          (group: any) => !newUnInvitedGroupsList.includes(group.show_group_id),
        );

        const unInvitedGroupsListArr = [...unInvitedGroupsList];

        newUnInvitedGroups.forEach((group: any) => unInvitedGroupsListArr.push(group));

        setInvitedGroupsList(invitedGroupsListArr);
        setUnInvitedGroupsList(unInvitedGroupsListArr);
      }
    },
    [invitedGroupsList, unInvitedGroupsList],
  );

  const handleChangeStream = (index: number) => {
    setSelectedStreamIndex(index);
  };

  const activeStreamObj: any = activeStream.length > 0 ? activeStream[selectedStreamIndex] : {};

  const getUnInvitedGroupsList = (groupsList: any) => {
    const filteredGroupsList: any = groupsList.filter(
      (group: any) => get(group, 'show_group_users', []).length > 0,
    );
    return filteredGroupsList;
  };

  return (
    <div className='manage-show-viewers-wrapper'>
      <ProgressLoader loading={viewerLoading || streamLoading || groupLoading || loading} />
      <div
        className={
          props.usersGroupsFlag
            ? 'left-nav-new show-admin-left-nav left-nav-pub'
            : 'left-nav margin-top-0'
        }
      >
        <InviteViewers
          showId={showId}
          activeStream={activeStreamObj}
          refreshUninviteList={refreshUninviteList}
          unInvitedUsersList={unInvitedUsersList}
          teamAdmin={teamAdmin}
          unInvitedGroupsList={getUnInvitedGroupsList(unInvitedGroupsList)}
          refreshUninviteGroupList={refreshUninviteGroupList}
          setLoading={setLoading}
          loading={loading}
          usersGroupsFlag={props.usersGroupsFlag}
        />
      </div>
      <div className={props.usersGroupsFlag ? 'right-form-container-new' : 'right-form-container'}>
        <div className='right-list-container'>
          <UnInviteViewers
            showId={showId}
            allActiveStream={activeStream}
            selectedStreamIndex={selectedStreamIndex}
            handleChangeStream={handleChangeStream}
            activeStream={activeStreamObj}
            refreshInviteList={refreshInviteList}
            invitedUsersList={invitedUsersList}
            teamAdmin={teamAdmin}
            invitedGroupsList={invitedGroupsList}
            refreshInviteGroupList={refreshInviteGroupList}
            setLoading={setLoading}
            loading={loading}
            usersGroupsFlag={props.usersGroupsFlag}
          />
        </div>
      </div>
    </div>
  );
};

export default MangeUser;
