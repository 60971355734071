import React, { useState, useEffect, useContext } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { ROLES } from '../../Utils/constants';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import ProgressLoader from '../../Components/Common/ProgressLoader';
import { PAGES, SUPERADMIN_PAGES, EVENT_TYPES } from '../../Utils/constants';
import { Mixpanel } from '../../Service/Mixpanel';
import OverLay from '../../Components/Common/OverLay';
import { get } from '../../Utils/helpers';
import algoliasearch from 'algoliasearch';
import { fetchAllShows } from '../../Service/show';
import { countryCodeData } from '../../Service/countryCodes';
import { AuthContext } from '../../Context/authContext';
import './index.css';
import { logEvent } from '../../Service/LogEvent';

interface CreateShowAdminProps {
  roleLabel: string;
  showId?: string;
}

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_SEARCH_KEY } = process.env;

const searchClient = algoliasearch(
  REACT_APP_ALGOLIA_APP_ID || '',
  REACT_APP_ALGOLIA_SEARCH_KEY || '',
);

const CreateShowAdmin = (props: CreateShowAdminProps) => {
  const currentUser = get(useContext(AuthContext), 'currentUser', {});

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [overlayDisplay, setOverlayDisplay] = useState(false);
  const [overlayMsg, setOverlaymsg] = useState('');

  const [user_title, setUserTitle] = useState('');
  const [user_name_first, setFirstName] = useState('');
  const [user_name_last, setLastName] = useState('');
  const [user_email, setEmail] = useState('');
  const [user_phone, setPhone] = useState('');
  const [show_id, setShowId] = useState('');
  const [comments, setComments] = useState('');

  const [shows, setShows] = useState([] as any);
  const [showTeamAdmin, setShowTeamAdmin] = useState(false);

  const [emailValid, setEmailValid] = useState(true);
  const [titleValid, setTitleValid] = useState(true);
  const [fNameValid, setfNameValid] = useState(true);
  const [lNameValid, setlNameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [showValid, setShowValid] = useState(true);

  const [errorEmail, setErrorEmail] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorfName, setErrorfName] = useState('');
  const [errorlName, setErrorlName] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorShow, setErrorShow] = useState('');

  const [countryCodes, setCountryCodes] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [allCountryCodes, setAllCountryCodes] = useState([]);
  const [ccValid, setCCValid] = useState(true); // cc --> Country Code
  const [errorCountryCode, setErrorCountryCode] = useState('');
  const [user_country_code, setUserCountryCode] = useState('');

  const fetchData = async () => {
    try {
      setSuccessMessage('');
      setErrorMessage('');
      setLoading(true);
      // fetch show list from algolia
      let result = await fetchAllShows();
      let showList = result?.hits
        .filter((item: any) => !item.parent_show_id)
        .map((item: any) => {
          return {
            key: item.objectID,
            value: item.objectID,
            text: item.show_name,
            feature_flags: item.feature_flags,
            show_name: item.show_name,
            created_ts: item.created_ts._seconds,
          };
        });
      // sort by last created show
      let sortedShow = showList?.sort((a: any, b: any) => b.created_ts - a.created_ts);

      setShows(sortedShow);

      setSuccessMessage(`Successfully pulled show list`);

      // call fetch country code data function to get country code data
      const countryCodesResponse = await countryCodeData(get(currentUser, 'user.accessToken', ''));

      setAllCountryCodes(countryCodesResponse);

      const countryCodes = countryCodesResponse.sort((a: any, b: any) =>
        get(a, 'value', '').localeCompare(get(b, 'value', '')),
      );
      setCountryCodes(countryCodes);
      setLoading(false);
    } catch (err: any) {
      const msg = 'Error while pulling invited show users data';
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    searchClient.clearCache();
    // Mixpanel
    Mixpanel.track('Visit', {
      Platform: 'Web',
      'Page Name': PAGES.SUPER_ADMIN_PAGE + '_' + SUPERADMIN_PAGES.INVITE_SHOW_ADMIN,
    });
    fetchData();
    console.log('show list: ', shows);
  }, [showTeamAdmin]);

  const clearFormFields = () => {
    setShowId('');
    setUserTitle('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setCountryCode('');
    setPhone('');
    setComments('');
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const showOverlayForThreeSeconds = async () => {
    setOverlayDisplay(true);
    await sleep(3000);
    setOverlayDisplay(false);
  };

  const create = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setSuccessMessage('');
      setErrorMessage('');

      let country_code = user_country_code;

      const inviteShowUserDetails = {
        user_name_first,
        user_name_last,
        user_email,
        show_id,
        role: 'ShowAdmin',
      };

      // Call Backend to Create Show Admin
      const input = {
        user_title,
        user_name_first,
        user_name_last,
        user_email,
        country_code,
        user_phone,
        show_id,
        comments,
        roles: { [ROLES.SHOW_ADMIN]: true },
      };

      const data = {
        api: api.users.create,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      const response = await backend.save(data, get(currentUser, 'user.accessToken', ''));
      const inviteShowUserEvent = logEvent(
        get(currentUser, 'accessToken', ''),
        EVENT_TYPES.INVITE_SHOW_USER,
        inviteShowUserDetails,
      );
      console.log('InviteShowUserEvent:', inviteShowUserEvent);
      await searchClient.clearCache();
      setLoading(false);

      setSuccessMessage(`Created show admin with id as ${response.uid}`);

      Mixpanel.track('Admin User Action', {
        Platform: 'Web',
        'Action Email': user_email,
        'Action Type': 'InviteUser',
        'Admin Type': 'SuperAdmin',
      });

      setOverlaymsg(`${user_name_first + ' ' + user_name_last} has been added`);

      clearFormFields();

      showOverlayForThreeSeconds();
    } catch (err: any) {
      const msg = 'Error while creating show admin !';
      if (err.errMessage) {
        if (err.errMessage === 'TOO_SHORT' || err.errMessage === 'TOO_LONG') {
          setErrorMessage(`Given mobile number is ${err.errMessage}`);
        } else {
          setErrorMessage(err.errMessage);
        }
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const createTeamAdmin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setSuccessMessage('');
      setErrorMessage('');

      let country_code = user_country_code;
      const show_name: any = shows.filter((item: any) => item.value === show_id)[0];

      const inviteShowUserDetails = {
        user_name_first,
        user_name_last,
        user_email,
        show_id,
        role: 'TeamAdmin',
      };
      // Call Backend to Create Show Admin
      const input = {
        user_title,
        user_name_first,
        user_name_last,
        user_email,
        country_code,
        user_phone,
        show_id,
        show_name: show_name.text,
        comments,
        roles: {
          [ROLES.SHOW_ADMIN]: true,
          [ROLES.SHOW_PUBLISHER]: true,
          [ROLES.SHOW_VIEWER]: true,
        },
        team_admin: true,
      };

      const data = {
        api: api.users.create,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      const response = await backend.save(data, get(currentUser, 'user.accessToken', ''));
      const inviteShowUserEvent = logEvent(
        get(currentUser, 'accessToken', ''),
        EVENT_TYPES.INVITE_SHOW_USER,
        inviteShowUserDetails,
      );
      console.log('InviteShowUserEvent:', inviteShowUserEvent);
      await searchClient.clearCache();
      setLoading(false);

      console.log(response);
      setSuccessMessage(`Created team admin with id as ${response.uid}`);

      Mixpanel.track('Admin User Action', {
        Platform: 'Web',
        'Action Email': user_email,
        'Action Type': 'InviteTeamAdmin',
        'Admin Type': 'SuperAdmin',
      });

      setOverlaymsg(`${user_name_first + ' ' + user_name_last} has been added`);

      clearFormFields();

      showOverlayForThreeSeconds();
    } catch (err: any) {
      const msg = 'Error while creating team admin !';
      if (err.errMessage) {
        if (err.errMessage === 'TOO_SHORT' || err.errMessage === 'TOO_LONG') {
          setErrorMessage(`Given mobile number is ${err.errMessage}`);
        } else {
          setErrorMessage(err.errMessage);
        }
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  // validation of form fields  //
  const validateshow = (event: any, data: String) => {
    console.log(data);
    //validation check
    if (!data) {
      setShowValid(false);
      setErrorShow('Please Select a show.');
    } else {
      setShowValid(true);
      setErrorShow('');
    }
  };
  const validateEmail = (event: any) => {
    const { value } = event.target;
    setEmail(value.trim());
    let validateEmail = value.trim();
    if (!validateEmail) {
      setEmailValid(false);
      setErrorEmail('Please enter email');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      setEmailValid(false);
      setErrorEmail('Invalid email format');
    } else {
      setEmailValid(true);
      setErrorEmail('');
    }
  };

  const validateTitle = (event: any) => {
    const { value } = event.target;
    setUserTitle(value.trim());
  };

  const validateFirstName = (event: any) => {
    const { value } = event.target;
    setFirstName(value.trim());
    let validatefName = value.trim();
    if (!validatefName) {
      setfNameValid(false);
      setErrorfName('Please enter your first name');
    } else {
      setfNameValid(true);
      setErrorfName('');
    }
  };

  const validateLastName = (event: any) => {
    const { value } = event.target;
    setLastName(value.trim());
    let validatelName = value.trim();
    if (!validatelName) {
      setlNameValid(false);
      setErrorlName('Please enter your last name');
    } else {
      setlNameValid(true);
      setErrorlName('');
    }
  };
  const searchCountryCode = (event: any) => {
    const { value } = event.target;
    if (value == '') {
      const country_Codes = allCountryCodes.sort((a: any, b: any) =>
        get(a, 'value', '').localeCompare(get(b, 'value', '')),
      );
      setCountryCodes(country_Codes);
    } else {
      const lower_val = value.charAt(0).toLowerCase();
      const startsWith = countryCodes.filter(
        (code: any) => code.value.toLowerCase().indexOf(lower_val) === 0,
      );
      setCountryCodes(startsWith);
    }
  };
  const validatePhoneText = (data: any) => {
    const currCountry: any = countryCodes.find((cc: any) => get(cc, 'value', '') === data.value);

    if (data && currCountry) {
      if (user_phone && !currCountry?.ignore_count && user_phone.length !== currCountry.count) {
        setPhoneValid(false);
        setErrorPhone(`Please enter ${currCountry.count} digit phone no`);
      } else {
        setPhoneValid(true);
        setErrorPhone('');
      }
    }
  };

  const validatePhone = (event: any) => {
    const { value } = event.target;
    const validatePhone = value.replace(/[^0-9]/, '');
    setPhone(validatePhone);
    // let validatePhone = value.trim();
    if (!validatePhone) {
      setPhoneValid(false);
      setErrorPhone('Please enter phone number');
    } else if (countryCode) {
      const currCountry: any = countryCodes.find((cc: any) => get(cc, 'value', '') === countryCode);
      const regexString = '^\\d{' + currCountry.count + '}$';
      const regex = new RegExp(regexString);

      if (
        countryCode === get(currCountry, 'value', '') &&
        !currCountry?.ignore_count &&
        !regex.test(validatePhone)
      ) {
        setPhoneValid(false);
        setErrorPhone(`Please enter ${currCountry.count} digit phone no`);
      } else {
        setPhoneValid(true);
        setErrorPhone('');
      }
    } else if (!countryCode && !/^\d{10}$/.test(validatePhone)) {
      setPhoneValid(false);
      setErrorPhone('Please enter 10 digit phone no');
    } else {
      setPhoneValid(true);
      setErrorPhone('');
    }
  };

  const validateCountryCode = (event: any, data: any) => {
    if (!data.value) {
      setCCValid(false);
      setErrorCountryCode('Please select country code');
    } else {
      setCCValid(true);
      setErrorCountryCode('');
      validatePhoneText(data);
    }
  };

  const handleCountryCodeChange = (e: any, data: any) => {
    setCountryCode(data.value);
    const currCountry: any = countryCodes.find((cc: any) => get(cc, 'value', '') === data.value);
    setUserCountryCode(currCountry.code);
    validateCountryCode(e, data);
  };

  return (
    <div className='createshowadmin'>
      <ProgressLoader loading={loading} />

      <div className='heading margin-0'>
        <p className='heading-text-center'>
          {props.roleLabel} : <span className='grey-text'>Invite Show Admin</span>
        </p>
        <hr className='top' />
      </div>

      <div className='form-content margin-left-0'>
        <form className='ui form'>
          <div className='field-and-icon'>
            <div className='field-icon-tv left-icon'></div>
            <div className='form-field no-overlap icon-input-field'>
              <label className='form-input-label mandatory'>To Show</label>
              <Dropdown
                placeholder='Select a show'
                className={showValid === false ? 'form-input error-border' : 'form-input'}
                fluid
                search
                selection
                options={shows}
                value={show_id}
                onChange={(e, data) => {
                  const showid: string = data.value as string;
                  setShowId(showid);
                  validateshow(e, showid);
                  let isTeamAdmin =
                    !!shows &&
                    shows.filter(
                      (item: any) =>
                        // return item.feature_flags.ALLOW_TEAM_ADMIN.map((x: any) => x.key == show_id)
                        get(item, 'feature_flags.ALLOW_TEAM_ADMIN', false) && item.key === showid,
                    );
                  if (showid != '') {
                    console.log(showid, `test if it's empty`);
                    let show_team_admin = isTeamAdmin?.map((x: any) =>
                      x.key === showid ? x.value : x.key,
                    );
                    setShowTeamAdmin(show_team_admin.length > 0 && true);
                    console.log(show_team_admin.length > 0 && true, 'check this ');
                  }

                  // setShowTeamAdmin(!!featureFlags && !!featureFlags[showid] &&
                  //   !!featureFlags[showid].ALLOW_TEAM_ADMIN);
                }}
              />
              {showValid === false && <span className='error-message'>{errorShow}</span>}
            </div>
          </div>

          <div className='field-and-icon'>
            <div className='field-icon-name left-icon'></div>
            <div className='form-field  icon-input-field'>
              <label className='form-input-label'>Title</label>
              <input
                className={titleValid === false ? 'form-input error-border' : 'form-input'}
                type='text'
                value={user_title}
                onChange={validateTitle}
              />
              {titleValid === false && <span className='error-message'>{errorTitle}</span>}
            </div>
          </div>

          <div className='field-and-icon'>
            <div className='field-icon-name left-icon'></div>
            <div className='form-field icon-input-field'>
              <div className='first-name'>
                <label className='form-input-label mandatory'>First Name</label>
                <input
                  className={fNameValid === false ? 'form-input error-border' : 'form-input'}
                  type='text'
                  value={user_name_first}
                  //onChange={(e) => setFirstName(e.target.value)}
                  onChange={validateFirstName}
                />
                {fNameValid === false && <span className='error-message'>{errorfName}</span>}
              </div>
              <div className='last-name'>
                <label className='form-input-label mandatory'>Last Name</label>
                <input
                  className={lNameValid === false ? 'form-input error-border' : 'form-input'}
                  type='text'
                  value={user_name_last}
                  //onChange={(e) => setLastName(e.target.value)}
                  onChange={validateLastName}
                />
                {lNameValid === false && <span className='error-message'>{errorlName}</span>}
              </div>
            </div>
          </div>

          <div className='field-and-icon'>
            <div className='field-icon-email left-icon'></div>
            <div className='form-field  icon-input-field'>
              <label className='form-input-label mandatory'>E-mail</label>
              <input
                className={emailValid === false ? 'form-input error-border' : 'form-input'}
                type='text'
                value={user_email}
                onChange={validateEmail}
              />
              {emailValid === false && <span className='error-message'>{errorEmail}</span>}
            </div>
          </div>

          <div className='field-and-icon'>
            <div className='field-icon-phone left-icon'></div>

            <div className='form-field icon-input-field'>
              <div className='country-code'>
                <label className='form-input-label mandatory'>Country Code</label>
                <Dropdown
                  placeholder='Select Code'
                  className={ccValid === false ? 'form-input error-border' : 'form-input'}
                  fluid
                  selection
                  search
                  options={countryCodes}
                  value={countryCode}
                  onKeyUp={searchCountryCode}
                  onChange={handleCountryCodeChange}
                />
                {ccValid === false && <span className='error-message'>{errorCountryCode}</span>}
              </div>
              <div className='phone-number'>
                <label className='form-input-label mandatory'>Phone</label>
                <input
                  className={phoneValid === false ? 'form-input error-border' : 'form-input'}
                  type='text'
                  value={user_phone}
                  //onChange={(e) => setPhone(e.target.value)}
                  onChange={validatePhone}
                />
                {phoneValid === false && <span className='error-message'>{errorPhone}</span>}
              </div>
            </div>
          </div>
          <div className='field-and-icon'>
            <div className='field-icon-note left-icon'></div>
            <div className='form-field icon-input-field'>
              <label className='form-input-label'>Notes</label>
              <textarea
                className='form-textarea'
                placeholder='Notes goes here'
                rows={2}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </div>
          </div>
          <div className='inviteAdminButtons' style={!showTeamAdmin ? { textAlign: 'center' } : {}}>
            <div className='inviteShowadminButton'>
              <Button
                className='violet-button, width_240'
                color='violet'
                loading={loading}
                onClick={create}
                disabled={
                  fNameValid === false ||
                  user_name_first === '' ||
                  lNameValid === false ||
                  user_name_last === '' ||
                  emailValid === false ||
                  user_email === '' ||
                  phoneValid === false ||
                  user_phone === '' ||
                  showValid === false ||
                  show_id === ''
                }
              >
                Invite Show Admin
              </Button>
            </div>
            {showTeamAdmin && (
              <div className='inviteTeamadminButton'>
                <Button
                  className='violet-button, width_240'
                  color='violet'
                  loading={loading}
                  onClick={createTeamAdmin}
                  disabled={
                    fNameValid === false ||
                    user_name_first === '' ||
                    lNameValid === false ||
                    user_name_last === '' ||
                    emailValid === false ||
                    user_email === '' ||
                    phoneValid === false ||
                    user_phone === '' ||
                    showValid === false ||
                    show_id === ''
                  }
                >
                  Invite Team Admin
                </Button>
              </div>
            )}
          </div>

          {errorMessage && (
            <>
              <div className='flex'>
                <span className='alert-icon'></span>
                <span className='error-message alert-text-message'>{errorMessage}</span>
              </div>
            </>
          )}
        </form>

        <OverLay overlayMsg={overlayMsg} display={overlayDisplay} />
      </div>
    </div>
  );
};

export default CreateShowAdmin;
