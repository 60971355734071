import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react';
import { get } from '../Utils/helpers';
import { auth } from '../Firebase/firebase';
import { shouldUseProxy } from '../Utils/featureFlags';
import { clearUserCredential, getUserCredential } from '../Firebase/storate';
import { getClientClaims, refreshIdToken } from '../Firebase/auth';

const AuthContext = createContext(null as any);

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const interval = useRef<NodeJS.Timeout | null>();
  const [currentUser, setCurrentUser] = useState({
    user: null as any,
    is2FASuccess: false as boolean,
    loading: true,
  });

  const authListener = useCallback(async () => {
    if (shouldUseProxy('firebase')) {
      // Implement reload function here
      const userInfo = getUserCredential();
      if (userInfo) {
        // Need to revalidate this as not getting the claims from the proxy
        const userClaims = await getClientClaims();
        setCurrentUser({
          user: userInfo.user,
          is2FASuccess: userClaims?.is2FACompleted,
          loading: false,
        });
      } else {
        setCurrentUser({
          user: null,
          is2FASuccess: false,
          loading: false,
        });
      }
      interval.current = setInterval(async () => {
        refreshIdToken();
      }, 59 * 60 * 1000); // Refresh token every hour

      return () => {
        if (interval.current) {
          console.log('clearing interval------');
          clearInterval(interval.current); // Clean up interval on component unmount
          clearUserCredential();
        }
      };
    }

    const authHandler = async (user: any) => {
      if (user) {
        const tokenResult = await user.getIdTokenResult(true);
        console.log('user', user);
        setCurrentUser({
          user,
          is2FASuccess: get(tokenResult, 'claims.is2FACompleted', false),
          loading: false,
        });
      } else {
        console.log('not a user');
        setCurrentUser({
          user: null,
          is2FASuccess: false,
          loading: false,
        });
      }
    };

    const tokenSubscribe = auth.onIdTokenChanged(authHandler);
    const authSubscribe = auth.onAuthStateChanged(authHandler);
    interval.current = setInterval(() => {
      auth.currentUser?.getIdToken(true);
    }, 59 * 60 * 1000); // Refresh token every hour

    return () => {
      if (interval.current) clearInterval(interval.current); // Clean up interval on component unmount
      tokenSubscribe(); // Clean up onIdTokenChanged on component unmount
      authSubscribe(); // Clean up onAuthStateChanged on component unmount
    };
  }, [setCurrentUser]);

  useEffect(() => {
    authListener();
  }, [authListener]);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
