export const ALGOLIA_ENDPOINTS = {
  FETCH_ALL_ACTIVE_SHOWS: '/algolia/activeUserShows',
  FETCH_ALL_SHOWS: '/algolia/allShows',
  FETCH_SHOW_BY_ID: '/algolia/showById',
  FETCH_MAILBOX_SHOWS: '/algolia/mailboxShows',
  FETCH_ACTIVE_SUPER_ADMIN: '/algolia/activeShowsSuperAdmin',
  FETCH_ACTIVE_STREAM: '/algolia/activeStream',
  FETCH_SHOW_USERS: '/algolia/showUsers',
  FETCH_SHOW_GROUPS: '/algolia/showGroups',
  FETCH_SHOW_GROUP_USERS: '/algolia/showGroupUsers',
  FETCH_SHOW_VIEWERS: '/algolia/showViewers',
  FETCH_STREAM_BY_SHOW_NAME: '/algolia/streamByShowName',
  FETCH_SEARCH: '/algolia/search',
};

export const AUTH_ENDPOINTS = {
  SIGN_IN: '/auth/firebase/signin',
  SIGN_UP: '/auth/firebase/signup',
  TOKEN_REFRESH: '/auth/firebase/refresh-token',
  CLIENT_CLAIMS: '/auth/firebase/claims',
  PASSWORD_RESET: '/auth/firebase/password-reset',
  PASSWORD_UPDATE: '/auth/firebase/password-update',
  VERIFY_RESET_CODE: '/auth/firebase/verify-reset-code',
  CONFIRM_RESET: '/auth/firebase/confirm-reset',
};

export const PROFILE_PIC_PROXY = '/profile-pic';
