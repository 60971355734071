const authKeyName = 'userInfo';

type StsTokenManager = {
  accessToken: string;
  expirationTime: string;
  refreshToken: string;
};

type UserCredential = {
  user: {
    uid: string;
    email: string;
    displayName: string;
    accessToken: string;
    stsTokenManager: StsTokenManager;
  };
  [key: string]: any;
};

export const setUserCredential = (userInfo: UserCredential) => {
  sessionStorage.setItem(authKeyName, JSON.stringify(userInfo));
};

export const getUserCredential = (): UserCredential | null => {
  try {
    const userInfo = sessionStorage.getItem(authKeyName);
    if (userInfo) {
      return JSON.parse(userInfo);
    }
    return null;
  } catch (error) {
    console.error('Error getting user credential:', error);
    return null;
  }
};

export const clearUserCredential = () => {
  sessionStorage.removeItem(authKeyName);
};
