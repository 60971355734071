import { getToken } from '../Firebase/auth';
const commonHeaders = {
  'Content-Type': 'application/json',
};

export const postProtectedAPI = async (url: string, data?: any) => {
  const bearerToken = await getToken();
  if (!bearerToken) {
    throw new Error('User not authenticated');
  }
  const response = await fetch(url, {
    method: 'POST',
    ...(data ? { body: JSON.stringify(data) } : {}),
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  if (!response.ok) {
    console.error('Error in postProtectedAPI', response);
    throw new Error('Unable to fetch data');
  }
  return response.json();
};

export const getProtectedAPI = async (url: string) => {
  const bearerToken = await getToken();
  if (!bearerToken) {
    throw new Error('User not authenticated');
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  if (!response.ok) {
    console.error('Error in getProtectedAPI', response);
    throw new Error('Unable to fetch data');
  }
  return response.json();
};

export const postAPI = async (url: string, data?: any) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { ...commonHeaders },
    ...(data ? { body: JSON.stringify(data) } : {}),
  });

  if (!response.ok) {
    throw new Error('Unable to fetch data');
  }

  return response.json();
};

export const getAPI = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: { ...commonHeaders },
  });

  if (!response.ok) {
    throw new Error('Unable to fetch data');
  }

  return response.json();
};
