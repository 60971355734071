import { auth } from './firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
  UserCredential,
  IdTokenResult,
} from 'firebase/auth';
import backend from '../Service/Backend';
import api from '../Service/Api';
import { ProxyConfig, shouldUseProxy } from '../Utils/featureFlags';
import { clearUserCredential, getUserCredential, setUserCredential } from './storate';
import { getProtectedAPI, postAPI } from '../Utils/callAPI';
import { AUTH_ENDPOINTS } from '../Utils/proxyEndPoints';

type RefreshTokenResponse = {
  accessToken: string;
  refreshToken: string;
  expirationTime: string;
};

// Sign In
export const doSignInWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<UserCredential | any> => {
  // Only use proxy if specifically enabled for firebase service
  if (shouldUseProxy('firebase')) {
    try {
      const userCredential = await postAPI(
        `${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.SIGN_IN}`,
        { email, password },
      );
      setUserCredential(userCredential);
      return userCredential;
    } catch (error) {
      console.error('Auth error:', error);
      throw new Error('Authentication failed');
    }
  }

  // Default: direct Firebase call
  return signInWithEmailAndPassword(auth, email, password);
};

// Sign Up
export const doCreateUserWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<UserCredential> => {
  if (shouldUseProxy('firebase')) {
    try {
      const userCredential = await postAPI(
        `${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.SIGN_UP}`,
        { email, password },
      );
      setUserCredential(userCredential);
      return userCredential;
    } catch (error) {
      console.error('Sign up error:', error);
      throw error;
    }
  }

  return createUserWithEmailAndPassword(auth, email, password);
};

// Get Token - Always get from Firebase directly as we need it for backend calls
export const getToken = async (): Promise<string> => {
  if (shouldUseProxy('firebase')) {
    const userInfo = getUserCredential();
    if (userInfo?.user?.accessToken) {
      return userInfo?.user?.accessToken;
    }
    return Promise.reject('No auth.currentUser!');
  }
  if (!auth.currentUser) {
    return Promise.reject('No auth.currentUser!');
  }
  return auth.currentUser.getIdToken(true);
};

// Password Reset
export const doPasswordReset = async (email: string): Promise<void> => {
  if (shouldUseProxy('firebase')) {
    try {
      await postAPI(`${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.PASSWORD_RESET}`, { email });
    } catch (error) {
      console.error('Password reset error:', error);
      throw new Error('Password reset failed');
    }
  } else {
    return sendPasswordResetEmail(auth, email);
  }
};

// Clear user claim (no proxy needed - backend call)
export const clear = async () => {
  try {
    const data = {
      api: api.otp.disableOTPAuthStatus,
    };
    const token = await getToken();
    await backend.save(data, token);
    return true;
  } catch (error) {
    console.log('error', error);
    return false;
  }
};

// Sign out (always local)
export const doSignOut = async () => {
  if (shouldUseProxy('firebase')) {
    clearUserCredential();
    return true;
  }
  try {
    await clear();
    await auth.signOut();
    return true;
  } catch (error) {
    console.log('error', error);
  }
};

// Rest of the functions remain unchanged as they're either local operations
// or backend calls that don't need proxying

export const getTokenResult = async (): Promise<IdTokenResult> => {
  if (shouldUseProxy('firebase')) {
    const userInfo = getUserCredential();
    if (userInfo?.user?.accessToken) {
      return userInfo?.user as any;
    }
    return Promise.reject('No auth.currentUser!');
  }
  if (auth.currentUser) {
    return auth.currentUser.getIdTokenResult();
  }
  return Promise.reject('No auth.currentUser!');
};

export const getUserId = (): string => {
  if (shouldUseProxy('firebase')) {
    const userInfo = getUserCredential();
    return userInfo?.user?.uid || '';
  }
  return auth?.currentUser?.uid || '';
};

export const getUserEmail = (): string => {
  if (shouldUseProxy('firebase')) {
    const userInfo = getUserCredential();
    return userInfo?.user?.email || '';
  }
  return auth?.currentUser?.email || '';
};

export const getUser = (): any => {
  if (shouldUseProxy('firebase')) {
    const userInfo = getUserCredential();
    if (userInfo?.user) {
      return userInfo.user;
    }
    return null;
  }
  return auth.currentUser ? auth.currentUser : null;
};

export const getUserRole = async () => {
  try {
    const idTokenResult: IdTokenResult = await getTokenResult();
    if (idTokenResult && idTokenResult.claims) {
      const { role } = idTokenResult.claims;
      if (!role) {
        return false;
      }
      return role;
    }
  } catch (err: any) {
    throw err;
  }
};

export const isAuthenticated = (): boolean => {
  const user: any = getUser();
  return !!user;
};

export const isInitialised = async () => {
  return new Promise((resolve) => {
    auth.onAuthStateChanged(resolve);
  });
};

// Password verification functions with proxy support
export const verifyPasswordResetCodeHandler = async (actionCode: string): Promise<string> => {
  if (shouldUseProxy('firebase')) {
    try {
      const data = await postAPI(
        `${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.VERIFY_RESET_CODE}`,
        { actionCode },
      );

      return data.email;
    } catch (error) {
      console.error('Verify reset code error:', error);
      throw error;
    }
  }

  return verifyPasswordResetCode(auth, actionCode);
};

export const confirmPasswordResetHandler = async (
  actionCode: string,
  newPassword: string,
): Promise<void> => {
  if (shouldUseProxy('firebase')) {
    try {
      await postAPI(`${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.CONFIRM_RESET}`, {
        actionCode,
        newPassword,
      });
    } catch (error) {
      console.error('Confirm password reset error:', error);
      throw new Error('Confirm password reset failed');
    }
  } else {
    return confirmPasswordReset(auth, actionCode, newPassword);
  }
};

/**
 * used only for proxy but can be used for firebase as well
 * @returns
 */
export const getClientClaims = async (): Promise<any> => {
  if (shouldUseProxy('firebase')) {
    try {
      return getProtectedAPI(`${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.CLIENT_CLAIMS}`);
    } catch (error) {
      console.error('Get client claims error:', error);
      throw new Error('Get client claims failed');
    }
  }

  return auth.currentUser?.getIdTokenResult();
};

export const refreshIdToken = async (): Promise<any> => {
  if (shouldUseProxy('firebase')) {
    console.log('getting refresh token');
    try {
      const userInfo = getUserCredential();
      if (!userInfo?.user?.stsTokenManager?.refreshToken) {
        console.info('skipping: refresh token not found');
        return null;
      }

      const response = await fetch(
        `${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.TOKEN_REFRESH}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo?.user?.stsTokenManager?.refreshToken}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Token refresh failed');
      }

      const refreshedInfo: RefreshTokenResponse = await response.json();
      userInfo.user.stsTokenManager.refreshToken = refreshedInfo.refreshToken;
      userInfo.user.stsTokenManager.accessToken = refreshedInfo.accessToken;
      userInfo.user.stsTokenManager.expirationTime = refreshedInfo.expirationTime;
      userInfo.accessToken = refreshedInfo.accessToken;
      setUserCredential(userInfo);
      return refreshedInfo;
    } catch (error) {
      console.error('Token refresh error:', error);
    }
  }
  return null;
};
