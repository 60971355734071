import { AES, enc } from 'crypto-js';
import api from '../Service/Api';
import backend from '../Service/Backend';
import { FEATURE_NAME } from './constants';
import { get } from './helpers';
import { fetchAllShows, showActiveUsers } from '../Service/show';

// Feature flag configuration interface
type ProxiedServices = 'firebase' | 'twilio' | 'pubnub' | 'algolia' | 'millicast' | 'zoom';
export interface FeatureFlagConfig {
  USE_PROXY_GATEWAY: boolean;
  PROXY_GATEWAY_URL: string;
  DIRECT_GATEWAY_URL: string;
  PROXIED_SERVICES: ProxiedServices[];
}

// Environment variables
const {
  REACT_APP_FF_KEY,
  REACT_APP_USE_PROXY_GATEWAY,
  REACT_APP_PROXY_GATEWAY_URL,
  REACT_APP_DIRECT_GATEWAY_URL,
} = process.env;

// Export feature flags configuration for third-party services
export const ProxyConfig: FeatureFlagConfig = {
  USE_PROXY_GATEWAY: REACT_APP_USE_PROXY_GATEWAY === 'true',
  // Using Cloudflare Workers domain pattern
  PROXY_GATEWAY_URL: REACT_APP_PROXY_GATEWAY_URL || 'https://proxy.pacpost.live',
  DIRECT_GATEWAY_URL: REACT_APP_DIRECT_GATEWAY_URL || 'https://backend.pacpost.live',
  // List of services that should go through proxy
  PROXIED_SERVICES: ['firebase', 'algolia'], // 'firebase', 'twilio', 'pubnub', 'algolia', 'millicast', 'zoom'] as const,
};

// Helper to determine if a service should use proxy
export const shouldUseProxy = (serviceName: ProxiedServices): boolean => {
  return ProxyConfig.USE_PROXY_GATEWAY && ProxyConfig.PROXIED_SERVICES.includes(serviceName);
};

// Keep existing feature flags implementation unchanged
export const getAllFeatureFlags = async (token: string) => {
  try {
    const data = {
      api: api.featureFlags.listAllFlags,
    };
    const flags = await backend.fetch(data, token);

    if (flags) {
      let feature_flags = flags;

      if (REACT_APP_FF_KEY) {
        const ffc = AES.encrypt(JSON.stringify(feature_flags), REACT_APP_FF_KEY).toString();
        sessionStorage.setItem('ff', ffc);
      }
    }
  } catch (err: any) {
    console.log("Couldn't get feature flag details");
  }
};

// Keep existing getAppFeatureFlags unchanged - it's a backend call
export const getAppFeatureFlags = async (
  {
    show_id,
    flag_name,
  }: {
    show_id: string;
    flag_name: string;
  },
  token: string,
) => {
  try {
    if (show_id) {
      const flagData = {
        api: api.featureFlags.listShowFlagsName,
        queryParam: {
          show_id: show_id,
          flag_name: flag_name,
        },
      };

      const flag: any = await backend.fetch(flagData, token);
      return get(flag, '0.feature_enable', flag_name === 'ALLOW_NEW_SHOW_VIEWER' ? true : false);
    }
    return false;
  } catch (err: any) {
    return false;
  }
};

// Rest of the existing code remains unchanged...
export const isFeatureIncluded = (feature_name: FEATURE_NAME, show_id: string): boolean => {
  if (!feature_name || !show_id) {
    return false;
  }

  fetchAllShows();
  showActiveUsers(show_id);
  const ffc = sessionStorage.getItem('ff');
  if (!ffc) {
    return false;
  }

  if (!REACT_APP_FF_KEY) {
    return false;
  }

  const bytes = AES.decrypt(ffc, REACT_APP_FF_KEY);
  var feature_flags = JSON.parse(bytes.toString(enc.Utf8));

  // Show Specific
  const show_flags = feature_flags[show_id];

  if (show_flags) {
    if (feature_name in show_flags) {
      return show_flags[feature_name];
    }
  }

  // Application specific
  const app_flags = feature_flags[-1];

  if (app_flags) {
    if (feature_name in app_flags) {
      return app_flags[feature_name];
    }
  }

  return false;
};

export const isTrustedDeviceFeatureEnabled = async (token: string) => {
  const featureData = {
    api: api.featureFlags.listFeatureFlags,
  };
  const flags = await backend.fetch(featureData, token);
  return get(get(flags, 'TRUSTED_DEVICE', ''), 'feature_enable', false);
};
