import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { Layout } from '../PageLayout/Layout';
import { PAGES, EVENT_TYPES } from '../../Utils/constants';
import { getAllFeatureFlags, shouldUseProxy } from '../../Utils/featureFlags';
import { auth, analytics } from '../../Firebase';
import backend from '../../Service/Backend';
import api from '../../Service/Api';
import ProgressLoader from '../Common/ProgressLoader';
import { Mixpanel } from '../../Service/Mixpanel';
import { get } from '../../Utils/helpers';
import { logEvent } from '../../Service/LogEvent';
import TermsOfService from '../Common/TermsOfService';
import PrivacyPolicy from '../Common/PrivacyPolicy';
import SubscriptionAgreement from '../Common/SubscriptionAgreement';
import callIcon from '../../Assets/icons/call-icon.svg';
import OtpResendCounter from './OtpResendCounter';
import { AuthContext } from '../../Context/authContext';
import ConfirmationalPopup from '../../Utils/ConfirmationalPopup';
import ImageCanvas from '../UserSettings/ImageCanvas';
import useNavigateToPage from '../../hooks/useNavigateToPage';
import { notification } from 'antd';

const Otp = () => {
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const setCurrentUser = get(useContext(AuthContext), 'setCurrentUser', {});
  const navigateToPage = useNavigateToPage();
  const navigationType = useNavigationType();
  const location: any = useLocation();
  const isAuthenticated = location.state ? location.state.isAuthenticated : '';
  const googleAuth = location.state ? location.state.googleAuth : '';
  const privacyTerm = location.state ? location.state.privacyTerm : '';
  const trustedDeviceFeature = location.state ? location.state.trustedDeviceFeature : false;
  const emailOtpFeature = location.state ? location.state.showEmailOtpOption : false;
  const { OTPSuccess, OTPFailure } = analytics;
  const [errorMessage, setErrorMessage] = useState('');
  const [otpAttemptMessage, setOtpAttemptMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [validateLoading, setValidateLoading] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);
  const [otpCallLoading, setOtpCallLoading] = useState(false);
  const [emailOtpLoading, setEmailOtpLoading] = useState(false);

  const [name, setName] = useState('');
  const [profile_Image_url, setProfileImageURL] = useState('');
  const [mobile, setMobile] = useState('');
  const [optmessage, setOtpMessage] = useState('');
  const [uid, setUid] = useState('');
  const [code, setCode] = useState('');
  const [otpValid, setOtpValid] = useState(true);
  const [errorOtp, setErrorOtp] = useState('');
  const [maxOtpTries, setMaxOtpTries] = useState(false);
  const [resendDisable, setResendDisable] = useState(true);
  const [callDisable, setCallDisable] = useState(false);

  const [twoFaType, setTwoFaType] = useState('SMS');
  const [qrcodeImg, setQRCodeimg] = useState();
  const [userDBData, setUserDBData] = useState(null as any);
  const [googleAuthSecret, setGoogleAuthSecret] = useState('');
  const [authyApprovalId, setAuthyApprovalId] = useState('');

  const [tosModalOpen, setToSModal] = useState(false);
  const [ppModalOpen, setPPModal] = useState(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [receiveCallModalOpen, setReceiveCallModalOpen] = useState(false);
  const [trustDevice, setTrustDevice] = useState(false);
  const [addedTrustDevice, setAddedTrustDevice] = useState(true);
  const [trustedDeviceModalOpen, setTrustedDeviceModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('no');
  const [isRefreshed, setIsRefreshed] = useState(false);

  //const resendTime = useRef(0);
  const attemptsRef = useRef(0);
  // let timer: any, timeRemaining: number = 0;
  // interface timeoutsType {
  //   [key: number]: number;
  // }
  // const getRetryTimeout = (attemptNumber: number) => {
  //   const retryTimeouts: timeoutsType = {
  //     1: 30,
  //     2: 40,
  //     3: 60,
  //     4: 90,
  //     5: 120,
  //   };

  //   const maxTimeout = 600;
  //   const defaultTimeout =
  //     maxTimeout - Object.values(retryTimeouts).reduce((a, b) => a + b);

  //   return retryTimeouts[attemptNumber] || defaultTimeout;
  // };

  // const countdown = (attempts: number) => {
  //   if (timeRemaining === 0) {
  //     clearInterval(timer);
  //     setResendDisable(false);
  //   } else {
  //     resendTime.current = timeRemaining;
  //     console.log("timeRemaining", timeRemaining);
  //     timeRemaining--;
  //   }
  // };

  // const startCountdown = (attempts: number) => {
  //   clearInterval(timer);
  //   setResendDisable(true);
  //   resendTime.current = getRetryTimeout(attempts);
  //   console.log("resendTime.current", resendTime.current)
  //   timer = setInterval(() => countdown(attempts), 1000);
  // };

  useEffect(() => {
    if (navigationType === 'POP') {
      setIsRefreshed(true);
      signOut();
    }
    if (trustedDeviceFeature) {
      fetchTrustedDevice();
    }
    return () => {
      if (navigationType === 'POP' && location && location.pathname === '/login') {
        signOut();
      }
    };
  }, []);

  const requestOtp = async (userDataValue: any) => {
    try {
      if (isRefreshed) {
        return;
      }
      setErrorMessage('');
      const { two_fa_type = 'SMS', google_auth_secret, twilio_authy_id } = userDataValue;
      setTwoFaType(!!two_fa_type ? two_fa_type : 'SMS');
      if ((!!two_fa_type && two_fa_type === 'Google Auth' && google_auth_secret) || !!googleAuth) {
        setResendDisable(true);
        setCallDisable(true);
        setGoogleAuthSecret(google_auth_secret);
        setOtpMessage('Enter the otp from google authenticator app to verify');
      } else if (!!two_fa_type && two_fa_type === 'Authy' && twilio_authy_id) {
        setResendDisable(true);
        setCallDisable(true);
        // create approval request
        try {
          const messageData = {
            message: 'Login requested for pacpost site',
            details: {},
          };
          const input = { authy_id: twilio_authy_id, messageData };
          const data = {
            api: {
              method: 'POST',
              endPoint: '/otp/authyVerify',
            },
            payLoad: JSON.stringify(input),
          };
          //setLoading(true);

          const authyUuid = await backend.save(data, get(authValue, 'accessToken', {}));
          if (authyUuid) {
            setAuthyApprovalId(authyUuid);
            setOtpMessage(
              'Once approved from authy verification, Please click the accept button to login',
            );
          }
        } catch (error) {
          const errorObj: any = error;
          setErrorMessage(errorObj.message);
        } finally {
          setLoading(false);
        }
      } else {
        const action = 'login';
        const data = {
          api: api.otp.requestOtp,
          queryParam: { action, authType: two_fa_type },
        };
        //setLoading(true);
        let response = await backend.fetch(data, get(authValue, 'accessToken', {}));
        if (response && response.message) {
          setOtpMessage(response.message);
          setMobile(response.phoneNumber);
          attemptsRef.current = attemptsRef.current + 1;
        }
      }
      if (!google_auth_secret && !!googleAuth) {
        await generateQrCode(null);
      }
      return true;
    } catch (error) {
      const errorObj: any = error;
      setErrorMessage(errorObj.errMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Mixpanel
    Mixpanel.track('Visit', {
      Platform: 'Web',
      'Page Name': PAGES.OTP_PAGE,
    });
    const signout = async () => {
      try {
        await auth.doSignOut();
        Mixpanel.track('Logout', { Platform: 'Web' });
        navigateToPage('/', { replace: true });
      } catch (error) {
        console.log(error);
      } finally {
        navigateToPage('/', { replace: true });
      }
    };

    const refreshProfile = async (uid: string) => {
      // Pull user data to show new profile image if it is changed
      try {
        setLoading(true);
        const data = {
          api: api.users.get,
        };
        const userDataValue = await backend.fetch(data, get(authValue, 'accessToken', {}));
        await requestOtp(userDataValue);
        setUserDBData(userDataValue);
        setProfileImageURL(get(userDataValue, 'imageUrlPath', ''));
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    console.log('authValue', authValue);
    console.log('isAuthenticated', isAuthenticated);
    if (isAuthenticated) {
      if (authValue.uid) {
        const { uid, displayName, photoURL } = authValue;
        setName(displayName);
        if (photoURL) {
          setProfileImageURL(photoURL);
        } else {
          setProfileImageURL('');
        }
        if (!profile_Image_url) {
          refreshProfile(uid);
        }
        setUid(uid);
      }
    } else {
      signout();
    }
  }, [authValue]);

  const fetchTrustedDevice = async () => {
    try {
      const deviceAll = {
        api: api.trustedDevice.deviceList,
      };
      setLoading(true);
      const devices = await backend.fetch(deviceAll, get(authValue, 'accessToken', {}));
      const deviceList = devices.filter((device: any) => device.status === 'active');
      if (deviceList.length > 0) {
        setAddedTrustDevice(true);
      } else {
        setAddedTrustDevice(false);
      }
      setLoading(false);
    } catch (err: any) {
      console.error(err);
    }
  };

  const isDeviceRegistered = async () => {
    try {
      const input = {};
      const data = {
        api: api.trustedDevice.deviceStatus,
        payLoad: JSON.stringify(input),
      };
      //setLoading(true);
      const res = await backend.fetch(data, get(authValue, 'accessToken', {}));
      return res;
    } catch (error) {
      const errorObj: any = error;
      setErrorMessage(errorObj.errMessage);
    } finally {
      setLoading(false);
    }
  };
  const renewDevice = async (id: string) => {
    try {
      const input = {};
      const data = {
        api: api.trustedDevice.renewDevice,
        urlParam: id,
        payLoad: JSON.stringify(input),
      };
      //setLoading(true);
      await backend.save(data, get(authValue, 'accessToken', {}));
    } catch (error) {
      const errorObj: any = error;
      setErrorMessage(errorObj.errMessage);
    } finally {
      setLoading(false);
    }
  };
  const addDevice = async () => {
    try {
      const input = {};
      const data = {
        api: api.trustedDevice.addNewDevice,
        payLoad: JSON.stringify(input),
      };
      //setLoading(true);
      const res = await backend.save(data, get(authValue, 'accessToken', {}));
    } catch (error) {
      const errorObj: any = error;
      setErrorMessage(errorObj.errMessage);
    } finally {
      setLoading(false);
    }
  };

  const validOtp = (event: any) => {
    setOtpAttemptMessage('');
    setErrorMessage('');
    const { value } = event.target;
    const validateOtp = value.replace(/[^0-9]/, '');
    setCode(validateOtp);
    if (!validateOtp) {
      setOtpValid(false);
      //setErrorOtp("Please enter Otp");
    } else {
      setOtpValid(true);
      setErrorOtp('');
    }
  };

  const resetState = () => {
    setErrorMessage('');
    setOtpAttemptMessage('');
    setMaxOtpTries(true);
  };

  const handleDeleteCancel = () => {
    setMaxOtpTries(false);
    signOut();
  };

  const navigateFun = async () => {
    try {
      const result: any = userDBData;
      const uid = get(authValue, 'uid', null);
      if (result && result.forcePassword) {
        // Forse reset Password
        navigateToPage('/resetpassword', { state: { from: '/otp', userId: uid }, replace: true });
      } else {
        //Pull All Feature Flags
        getAllFeatureFlags(get(authValue, 'accessToken', {}));
        // To:do: May be check the user role if he has any before going to intermediate screen
        logEvent(get(authValue, 'accessToken', {}), EVENT_TYPES.LOGIN_SUCCESS);
        //refresh
        setCurrentUser((preUser: any) => ({ ...preUser, loading: true }));
        await auth.getToken();
        if (shouldUseProxy('firebase')) {
          setCurrentUser((preUser: any) => ({ ...preUser, loading: false, is2FASuccess: true }));
        }
        navigateToPage('/showselection', { state: { userId: uid }, replace: true });
      }
    } catch (error) {
      console.log(error);
      const errorObj: any = error;
      setErrorMessage(errorObj.message);
    } finally {
      setLoading(false);
    }
  };

  const generateQrCode = async (event: any) => {
    try {
      if (!!event) {
        event.preventDefault();
        event.stopPropagation();
      }
      setErrorMessage('');
      const data = {
        api: {
          method: 'GET',
          endPoint: '/otp/googleAuth',
        },
      };
      setValidateLoading(true);
      const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
      if (result && result.qrcodeImg) {
        setQRCodeimg(result.qrcodeImg);
        setOtpMessage('Scan the QR code and enter the OTP using google authenticator app.');
      }
      if (result && result.googleAuthSecret) {
        setGoogleAuthSecret(result.googleAuthSecret);
      }
    } catch (error) {
      const errorObj: any = error;
      if (errorObj.errMessage) {
        setErrorMessage(errorObj.errMessage);
      } else {
        setErrorMessage('Error in QR code generation');
      }
    } finally {
      setValidateLoading(false);
    }
  };

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleConfirmClick = () => {
    setTrustDevice(selectedOption === 'yes');
    setTrustedDeviceModalOpen(false);
  };

  const handleRequestOtp = async () => {
    try {
      setLoading(true);
      const data = {
        api: api.users.get,
      };
      const userDataValue = await backend.fetch(data, get(authValue, 'accessToken', {}));
      await requestOtp(userDataValue);
    } catch (err: any) {
      console.error('Error requesting OTP:', err);
      setErrorMessage(err.message || 'An error occurred while requesting OTP');
    } finally {
      setLoading(false);
    }
  };

  const validateOtp = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      setErrorMessage('');
      let input: any = {
        otp: code,
        action: 'login',
        authType: twoFaType,
        privacyTerm: privacyTerm,
      };
      let data = {
        api: api.otp.validateOtp,
        payLoad: JSON.stringify(input),
      };

      if ((twoFaType && twoFaType === 'Google Auth') || !!googleAuth) {
        input = {
          userId: uid,
          token: code,
          action: 'login',
          authSecret: googleAuthSecret,
          privacyTerm: privacyTerm,
        };
        data = {
          api: api.otp.validateGoogleAuthOtp,
          payLoad: JSON.stringify(input),
        };
      }
      let result = null;
      setValidateLoading(true);
      if (twoFaType && twoFaType === 'Authy' && userDBData.twilio_authy_id && !googleAuth) {
        const dataVal = {
          api: api.otp.validateAuthy,
          queryParam: { authyApprovalId, action: 'login', privacyTerm },
        };
        result = await backend.fetch(dataVal, get(authValue, 'accessToken', {}));
      } else {
        result = await backend.save(data, get(authValue, 'accessToken', {}));
      }

      if (result) {
        OTPSuccess();
        setCurrentUser((preUser: any) => ({ ...preUser, loading: true }));
        await auth.getToken();
        if (shouldUseProxy('firebase')) {
          setCurrentUser((preUser: any) => ({ ...preUser, loading: false, is2FASuccess: true }));
        }
        if ((twoFaType && twoFaType === 'Google Auth' && googleAuthSecret) || !!googleAuth) {
          const input = {
            two_fa_type: twoFaType,
            google_auth_secret: googleAuthSecret,
          };
          const data = {
            api: api.users.edit,
            payLoad: JSON.stringify(input),
          };
          const response = await backend.save(data, get(authValue, 'accessToken', {}));
          console.log(response);
        }
        if (trustDevice && trustedDeviceFeature) {
          const statusRec = await isDeviceRegistered();
          if (!statusRec.isRegister) {
            await addDevice();
          } else {
            await renewDevice(statusRec.id);
          }
        }
        // Navigate
        navigateFun();
      } else {
        throw new Error('Invalid/Expired OTP');
      }
    } catch (error) {
      OTPFailure();
      const msg =
        twoFaType === 'Authy'
          ? 'Authy Validation Failed'
          : 'Your account is suspended, as you have exceeded the maximum number of verification attempts. Contact Admin!';
      const { errMessage, data, errCode } = error;

      if (errMessage) {
        setErrorMessage(errMessage);
      } else {
        setErrorMessage(msg);
      }
      if (data) {
        const { attempts_left } = data;
        if (attempts_left >= 0) {
          const msg = `You have ${attempts_left} chances left`;
          setOtpAttemptMessage(msg);
        }
        if (attempts_left >= 0) {
          const msg = `You have ${attempts_left} chances left`;
          setOtpAttemptMessage(msg);
        }
      } else if (errCode === 40104) {
        resetState();
      }
    } finally {
      setValidateLoading(false);
    }
  };

  const receiveCall = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setCallDisable(true);
    setReceiveCallModalOpen(true);
    try {
      event.preventDefault();
      event.stopPropagation();

      const action = 'login';
      const data = {
        api: api.otp.requestOtp,
        queryParam: { action, channel: 'call' },
      };
      setErrorMessage('');

      setOtpCallLoading(true);
      let response = await backend.fetch(data, get(authValue, 'accessToken', {}));
      if (response && response.message && response.phoneNumber) {
        setOtpMessage(response.message);
        setMobile(response.phoneNumber);
      }
    } catch (error) {
      const errorObj: any = error;
      setErrorMessage(errorObj.message);
    } finally {
      setOtpCallLoading(false);
    }
  };

  const requestEmailOtp = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setEmailOtpLoading(true);
      event.preventDefault();
      event.stopPropagation();
      setTwoFaType('EMAIL');
      const action = 'login';
      const data = {
        api: api.otp.requestOtp,
        queryParam: { action, authType: 'EMAIL' },
      };
      let response = await backend.fetch(data, get(authValue, 'accessToken', {}));
      setErrorMessage('');

      if (response && response.message) {
        setOtpMessage(response.message);
        notification.success({
          message: 'Enter the otp sent to your Email',
          placement: 'topRight',
        });
      }
    } catch (error) {
      const errorObj: any = error;
      setErrorMessage(errorObj.message);
    } finally {
      setEmailOtpLoading(false);
    }
  };

  const resendCode = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      const action = 'login';
      const data = {
        api: api.otp.requestOtp,
        queryParam: { action },
      };
      setErrorMessage('');
      setResendDisable(true);
      setResendOTPLoading(true);
      let response = await backend.fetch(data, get(authValue, 'accessToken', {}));

      if (response && response.message) {
        setOtpMessage(response.message);
        setMobile(response.phoneNumber);
        attemptsRef.current = attemptsRef.current + 1;
      }
    } catch (error) {
      const errorObj: any = error;
      setErrorMessage(errorObj.message);
    } finally {
      setResendOTPLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      await auth.doSignOut();
      Mixpanel.track('Logout', { Platform: 'Web' });
      navigateToPage('/', { replace: true });
      setIsRefreshed(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      navigateToPage('/', { replace: true });
    }
  };

  const userData = userDBData;
  const forcePasswordCheck = userData?.forcePassword || false;

  console.log('is loading: ', loading);
  return (
    <Layout page={PAGES.OTP_PAGE}>
      <ProgressLoader loading={loading} />

      <div className='left-content'>
        <div className='twofa_image'></div>
      </div>

      <div className='right-content'>
        <div className='right-container'>
          <div>
            <div className={!!profile_Image_url ? 'user_image' : 'user_image user_no_image'}>
              {profile_Image_url && (
                <ImageCanvas
                  className={!!profile_Image_url ? 'user_image' : 'user_image user_no_image'}
                  imageUrl={profile_Image_url}
                />
              )}
            </div>
            <p className='auth-heading-text'>Welcome {name}</p>
            <hr className='top' />
          </div>

          <div className='form-content'>
            <form className='ui form'>
              {/* Semantic UI form */}
              <div className='form-field'>
                <p className='lable-text'>
                  {optmessage}
                  {twoFaType !== 'EMAIL' && <span className='purple_text'> {mobile}</span>}
                </p>
                <input
                  style={{ display: authyApprovalId ? 'none' : 'block' }}
                  className={otpValid === false ? 'form-input error-border' : 'form-input'}
                  type='text'
                  value={code}
                  onChange={validOtp}
                />
                {/* <div onClick={(e) => generateQrCode(e)} style={{ display: (twoFaType && twoFaType === 'Google Auth') || googleAuth ? 'block' : 'none'}}>
                  <a href="#" className="forgot-message"> Forgot Google Auth? </a>
                </div> */}
                {otpValid === false && <span className='error-message'>{errorOtp}</span>}
              </div>
              <div
                className='auth-button'
                style={{
                  marginTop: '5px',
                  textAlign: 'center',
                  display: qrcodeImg ? 'block' : 'none',
                }}
              >
                <img alt='qrcodeImage' src={qrcodeImg} />
              </div>
              <div className='auth-button'>
                <Button
                  className='auth-violet-button'
                  color='violet'
                  loading={validateLoading}
                  onClick={validateOtp}
                  disabled={(otpValid === false || code === '') && twoFaType !== 'Authy'}
                >
                  {twoFaType !== 'Authy' || !!googleAuth ? 'Verify Code' : 'Accept'}
                </Button>
              </div>
              <br />
              {!addedTrustDevice && trustedDeviceFeature && !forcePasswordCheck && (
                <div className='ui checkbox otp-trust-device'>
                  <label></label>
                  <input
                    placeholder=''
                    type='checkbox'
                    checked={selectedOption === 'yes'}
                    onClick={(e) => setTrustedDeviceModalOpen(true)}
                  />
                  <label>
                    <span className='form-input-label'>Trust the device for 30 days</span>
                  </label>
                </div>
              )}
              {errorMessage && (
                <>
                  <div className='flex'>
                    <span className='alert-icon'></span>
                    <span className='error-message alert-text-message'>{errorMessage}</span>
                  </div>
                </>
              )}
              {otpAttemptMessage && (
                <>
                  <div className='flex'>
                    <span className='alert-icon'></span>
                    <span className='error-message alert-text-message'>{otpAttemptMessage}</span>
                  </div>
                </>
              )}
            </form>
          </div>

          <hr className='bottom' />

          <div className='form-content'>
            <div className='otp-resend-container'>
              <p className='bottom-lable'>Didn’t Receive a Code?</p>
              <Button
                disabled={resendDisable}
                className='auth-inverted-violet-button call-button'
                color='violet'
                loading={resendOTPLoading}
                onClick={resendCode}
              >
                Resend
              </Button>
            </div>
            <div className='center-button-otp'>
              {emailOtpFeature && (
                <>
                  <Button
                    className='auth-inverted-violet-button call-button'
                    color='violet'
                    loading={emailOtpLoading}
                    onClick={twoFaType !== 'EMAIL' ? requestEmailOtp : handleRequestOtp}
                    disabled={twoFaType === 'EMAIL' && (!otpValid || code === '')}
                  >
                    Receive email 2FA
                  </Button>
                  <span
                    className='bold-text'
                    style={{
                      display:
                        twoFaType &&
                        (twoFaType === 'Google Auth' || twoFaType === 'Authy' || googleAuth)
                          ? 'none'
                          : 'block',
                    }}
                  >
                    or
                  </span>
                </>
              )}
              <Button
                className='auth-inverted-violet-button call-button'
                color='violet'
                loading={otpCallLoading}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                disabled={callDisable}
                onClick={receiveCall}
              >
                <img
                  src={callIcon}
                  alt=''
                  className='call-icon'
                  style={{
                    display:
                      twoFaType &&
                      (twoFaType === 'Google Auth' || googleAuth || twoFaType === 'Authy')
                        ? 'none'
                        : 'block',
                  }}
                />
                Receive Phone Call
              </Button>
            </div>
            <p style={{ marginTop: '10px' }} className='bottom-lable'>
              {twoFaType === 'SMS' ? (
                <OtpResendCounter
                  resendDisable={resendDisable}
                  setResendDisable={setResendDisable}
                />
              ) : null}
            </p>
            {/* <div>
              <Button
                  className="auth-violet-button"
                  color="violet"
                  onClick={signOut}
                >
                Sign Out
              </Button>
            </div> */}
            <div style={{ marginTop: '45px' }}>
              <label className='twofa-terms'>
                By creating an account or continuing to use a Pacpost Live you acknowledge and agree
                that you have acceped the{' '}
                <b className='terms-policy' onClick={() => setToSModal(true)}>
                  Terms of Service
                </b>{' '}
                and reviewed the{' '}
                <b className='terms-policy' onClick={() => setPPModal(true)}>
                  Privacy Policy
                </b>{' '}
                and{' '}
                <b className='terms-policy' onClick={() => setSubscriptionModalOpen(true)}>
                  Subscription Agreement
                </b>
              </label>
            </div>
          </div>
        </div>
      </div>
      {tosModalOpen === true && (
        <Modal
          centered
          open={tosModalOpen}
          size={'large'}
          onClose={() => setToSModal(false)}
          onOpen={() => setToSModal(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <TermsOfService />
          </Modal.Content>
          <Modal.Actions>
            <Button className='purple-button' onClick={() => setToSModal(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {ppModalOpen === true && (
        <Modal
          centered
          open={ppModalOpen}
          size={'large'}
          onClose={() => setPPModal(false)}
          onOpen={() => setPPModal(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <PrivacyPolicy />
          </Modal.Content>
          <Modal.Actions>
            <Button className='purple-button' onClick={() => setPPModal(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {subscriptionModalOpen === true && (
        <Modal
          centered
          open={subscriptionModalOpen}
          size={'large'}
          onClose={() => setSubscriptionModalOpen(false)}
          onOpen={() => setSubscriptionModalOpen(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <SubscriptionAgreement />
          </Modal.Content>
          <Modal.Actions>
            <Button className='purple-button' onClick={() => setSubscriptionModalOpen(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {receiveCallModalOpen && (
        <Modal
          centered
          open={receiveCallModalOpen}
          size={'tiny'}
          onClose={() => setReceiveCallModalOpen(false)}
          onOpen={() => setReceiveCallModalOpen(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            You will receive a phone call on your registered mobile device with 2FA code.
          </Modal.Content>
          <Modal.Actions>
            <Button className='purple-button' onClick={() => setReceiveCallModalOpen(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {trustedDeviceModalOpen && (
        <Modal
          centered
          open={trustedDeviceModalOpen}
          size={'large'}
          onClose={() => setTrustedDeviceModalOpen(false)}
          onOpen={() => setTrustedDeviceModalOpen(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          header={'small'}
        >
          <Modal.Header>Register this device?</Modal.Header>
          <Modal.Content>
            Come here often? If this is a personal device, we can remember it for next time, and you
            won't need a security code to log in. You should only do this for devices you trust, not
            for shared or public ones.
            <div className='trusted-device-confirm'>
              <input
                type='radio'
                value='yes'
                checked={selectedOption === 'yes'}
                onChange={handleOptionChange}
              />
              <label className=''>
                Yes - this is a <b>trusted computer or device</b> (like a home or personal work
                computer)
              </label>
            </div>
            <div className='trusted-device-confirm'>
              <input
                type='radio'
                value='no'
                checked={selectedOption === 'no'}
                onChange={handleOptionChange}
              />
              <label className=''>
                No - this is a <b>shared/public computer or device</b> (like a friend's or those in
                a library)
              </label>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className='trusted-device-confirm-btn'>
              <Button className='purple-button' onClick={handleConfirmClick}>
                CONFIRM
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      )}
      <ConfirmationalPopup
        open={maxOtpTries}
        data={
          'Your account is suspended, as you have exceeded maximum tries for verify code. Contact Admin!'
        }
        onCancel={handleDeleteCancel}
        onOk={signOut}
      />
    </Layout>
  );
};

export default Otp;
