import React, { useEffect, useRef } from 'react';
import { shouldUseProxy } from '../../Utils/featureFlags';
import { PROFILE_PIC_PROXY } from '../../Utils/proxyEndPoints';

interface ICanvasType {
  imageUrl: string;
  onClick?: () => void;
  className?: string;
  style?: any;
}

const { REACT_APP_PROXY_GATEWAY_URL } = process.env;

const ImageCanvas = ({ imageUrl, onClick, className, style }: ICanvasType) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    };

    img.src = shouldUseProxy('firebase')
      ? `${REACT_APP_PROXY_GATEWAY_URL}${PROFILE_PIC_PROXY}?picUrl=${encodeURIComponent(imageUrl)}`
      : imageUrl;
  }, [imageUrl]);

  return <canvas className={className} ref={canvasRef} style={style} onClick={onClick} />;
};

export default ImageCanvas;
