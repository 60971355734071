import React, { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import ProgressLoader from '../Common/ProgressLoader';
import './Algolia.css';
import { shouldUseProxy } from '../../Utils/featureFlags';
import { postProtectedAPI } from '../../Utils/callAPI';
import { ALGOLIA_ENDPOINTS } from '../../Utils/proxyEndPoints';

const {
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_USER_INDEX_NAME,
} = process.env;

const searchClient = algoliasearch(
  REACT_APP_ALGOLIA_APP_ID || '',
  REACT_APP_ALGOLIA_SEARCH_KEY || '',
);
const algoliaProxyEnabled = shouldUseProxy('algolia');

const AlgoliaHOC = (Component: any, props: any) => {
  const { config = {} } = props;
  const {
    filters = '',
    setDataList = null,
    hitsPerPage = 5000,
    dataList,
    attributesToRetrieve,
    algoliaIndex,
    refresh,
    algoliaLoading = true,
    publisherCount = false,
  } = config;
  const index = searchClient.initIndex(algoliaIndex || '');
  const userIndex = !!publisherCount
    ? searchClient.initIndex(REACT_APP_ALGOLIA_USER_INDEX_NAME || '')
    : null;
  const [loading, setLoading] = useState(!!algoliaLoading);
  const [emptyHits, setEmptyHits] = useState(false);
  const [data, setData] = useState({});

  const getAlgolia = async (page = 0) => {
    setLoading(!!algoliaLoading ? true : false);
    const reqPayload = {
      filters,
      hitsPerPage,
      page,
      attributesToRetrieve,
      attributesToHighlight: [],
    };
    const result = algoliaProxyEnabled
      ? await postProtectedAPI(ALGOLIA_ENDPOINTS.FETCH_SEARCH, {
          indexName: algoliaIndex,
          ...reqPayload,
        })
      : await index.search('', reqPayload);

    if (!!publisherCount && !!userIndex) {
      const userPayload = {
        filters: 'role_type:ShowPublisher',
        hitsPerPage,
        page,
        attributesToRetrieve,
        attributesToHighlight: [],
      };
      const userList = algoliaProxyEnabled
        ? await postProtectedAPI(ALGOLIA_ENDPOINTS.FETCH_SEARCH, {
            indexName: REACT_APP_ALGOLIA_USER_INDEX_NAME,
            ...userPayload,
          })
        : await userIndex.search('', userPayload);

      const counts: any = {};
      for (const user of userList.hits) {
        const { show_name = '' } = user as any;
        counts[show_name] = (counts[show_name] || 0) + 1;
      }
      const updatedResult = { ...result };

      updatedResult.hits.map((res: any) => {
        const { show_name } = res;
        res.publisherCount = counts[show_name] || 0;
        return res;
      });
      setData(updatedResult);
      setDataList(updatedResult.hits);
    } else {
      setData(result);
      setDataList(result.hits);
    }
    setEmptyHits(result.hits.length === 0);
    setLoading(false);
  };

  useEffect(() => {
    //searchClient.clearCache();
    if (refresh) {
      searchClient.clearCache();
    }
    if (dataList.length === 0 && !emptyHits) {
      getAlgolia();
    } else {
      const updatedData = {
        ...data,
        hits: JSON.parse(JSON.stringify(dataList)),
      };
      setData(updatedData);
    }
  }, [dataList]);

  return (
    <>
      <ProgressLoader loading={loading} />
      <Component data={data} getAlgolia={getAlgolia} />
    </>
  );
};

export default AlgoliaHOC;
