import algoliasearch from 'algoliasearch';
import { FEATURE_NAME } from '../Utils/constants';
import { get } from '../Utils/helpers';
import { shouldUseProxy } from '../Utils/featureFlags';
import { getProtectedAPI, postProtectedAPI } from '../Utils/callAPI';
import { ALGOLIA_ENDPOINTS } from '../Utils/proxyEndPoints';

const {
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_SHOW_INDEX_NAME,
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_USER_INDEX_NAME,
  REACT_APP_ALGOLIA_STREAM_INDEX_NAME,
  REACT_APP_ALGOLIA_SHOW_GROUP_INDEX_NAME,
  REACT_APP_ALGOLIA_SHOW_GROUP_USER_INDEX_NAME,
  REACT_APP_PROXY_GATEWAY_URL,
} = process.env;

export const searchClient = algoliasearch(
  REACT_APP_ALGOLIA_APP_ID || '',
  REACT_APP_ALGOLIA_SEARCH_KEY || '',
);

// fetch show list from Algolia
export const fetchAllShows = async () => {
  try {
    if (shouldUseProxy('algolia')) {
      return getProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_ALL_SHOWS}`,
      ) as any;
    }

    const index = searchClient.initIndex(REACT_APP_ALGOLIA_SHOW_INDEX_NAME || '');
    const result = await index.search('', {
      hitsPerPage: 10000,
    });
    return result;
  } catch (error) {
    console.log('if show list fetch failed::', error);
  }
};

// fetch show by id from Algolia
export const fetchShowById = async (show_id: string) => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_SHOW_BY_ID}`,
        {
          showId: show_id,
        },
      );
    }
    const index = searchClient.initIndex(REACT_APP_ALGOLIA_SHOW_INDEX_NAME || '');
    const result = await index.search('', {
      filters: `objectID:${show_id}`,
    });

    return result;
  } catch (error) {
    console.log('if show by id fetch failed::', error);
  }
};

// fetch show feature flags from Algolia

export const isFeatureIncluded = async (feature_name: FEATURE_NAME, show_id: string) => {
  if (!feature_name || !show_id) {
    return false;
  }

  try {
    const result = await fetchShowById(show_id);

    // SHOW FLAGS
    // @ts-ignore
    const show_flags = result.hits[0]?.feature_flags;
    if (show_flags) {
      //feature as key present in feature_flags
      if (feature_name in show_flags) {
        return show_flags[feature_name];
      }
    }
    //  // Application specific
    // const app_flags = show_flags[-1]
    // if (app_flags) {
    //   if (feature_name in app_flags) {

    //     return app_flags[feature_name]
    //   }
    // }
    return false;
  } catch (error) {
    console.log('if show by id fetch failed::', error);
  }
};

export const fetchFeatures = async (show_id: string) => {
  if (!show_id) {
    return false;
  }
  try {
    const result = await fetchShowById(show_id);

    // SHOW FLAGS
    // @ts-ignore
    const hits = get(result, 'hits', null);
    const show_flags = get(hits[0], 'feature_flags', null);
    if (show_flags) {
      return show_flags;
    }
    return false;
  } catch (error) {
    console.log('if show by id fetch failed::', error);
  }
};

// fetch show active users from Algolia
export const showActiveUsers = async (show_id: any) => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_SHOW_USERS}`,
        {
          showId: show_id,
        },
      );
    }
    const index = searchClient.initIndex(REACT_APP_ALGOLIA_USER_INDEX_NAME || '');
    const result = await index.search('', {
      filters: `show_id:${show_id} OR invite:true`,
      hitsPerPage: 10000,
    });

    return result;
  } catch (error) {
    console.log('if show by id fetch failed::', error);
  }
};

// fetch show group active users from Algolia
export const showGroups = async (show_id: string, show_group_id: string) => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_SHOW_GROUPS}`,
        {
          showId: show_id,
          showGroupId: show_group_id,
        },
      );
    }

    const index = searchClient.initIndex(REACT_APP_ALGOLIA_SHOW_GROUP_INDEX_NAME || '');
    const result = await index.search('', {
      filters: `show_id:${show_id} AND show_group_id:${show_group_id}`,
      hitsPerPage: 10000,
    });

    return result;
  } catch (error) {
    console.log('if show by id fetch failed::', error);
  }
};

// fetch show group active users from Algolia
export const algoliaShowGroupUsers = async (show_id: string, user_id: string) => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_SHOW_GROUP_USERS}`,
        {
          showId: show_id,
          userId: user_id,
        },
      );
    }

    const index = searchClient.initIndex(REACT_APP_ALGOLIA_SHOW_GROUP_USER_INDEX_NAME || '');
    const result = await index.search('', {
      filters: `show_id:${show_id} AND user_id:${user_id}`,
      hitsPerPage: 10000,
    });

    return result;
  } catch (error) {
    console.log('if show by id fetch failed::', error);
  }
};

// fetch show active streams from Algolia
export const showActiveStreams = async (show_id: any) => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_ACTIVE_STREAM}`,
        { showId: show_id },
      );
    }

    const index = searchClient.initIndex(REACT_APP_ALGOLIA_STREAM_INDEX_NAME || '');
    const result = await index.search('', {
      filters: `show_id:${show_id}`,
      hitsPerPage: 10000,
    });

    return result;
  } catch (error) {
    console.log('if show by id fetch failed::', error);
  }
};

// STREAM_INDEX_NAME

// fetch All active shows from Algolia
export const fetchActiveUserShows = async (user_id?: any): Promise<any> => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_ALL_ACTIVE_SHOWS}`,
        { userId: user_id },
      );
    }

    const index = searchClient.initIndex(REACT_APP_ALGOLIA_USER_INDEX_NAME || '');
    const result = await index.search('', {
      hitsPerPage: 10000,
      filters: `user_show_role_status:Active AND user_status:Active AND user_id:${user_id}  AND (show_id: -1 OR show_status:Active )`,
    });
    return result;
  } catch (error) {
    console.log('if show list fetch failed::', error);
  }
};

// fetch mailbox shows from Algolia
export const fetchMailboxShows = async (showIds: string[]) => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_MAILBOX_SHOWS}`,
        { showIds },
      );
    }

    const index = searchClient.initIndex(REACT_APP_ALGOLIA_SHOW_INDEX_NAME || '');
    const result = await index.search('', {
      hitsPerPage: 10000,
      filters: `${showIds.join(' OR ')}`,
    });
    const mailBoxShow = get(result, 'hits', []).filter(
      (shows: any) => !!get(shows, 'feature_flags.ALLOW_MASSIV', false),
    );
    return mailBoxShow;
  } catch (error) {
    console.log('if show list fetch failed::', error);
  }
};

// fetch All active super admin from Algolia
export const fetchActiveSuperAdmin = async () => {
  try {
    if (shouldUseProxy('algolia')) {
      return getProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_ACTIVE_SUPER_ADMIN}`,
      );
    }

    const index = searchClient.initIndex(REACT_APP_ALGOLIA_USER_INDEX_NAME || '');
    const result = await index.search('', {
      hitsPerPage: 10000,
      filters: `user_show_role_status:Active AND user_status:Active  AND (show_id: -1)`,
    });
    return result;
  } catch (error) {
    console.log('if show list fetch failed::', error);
  }
};

export const fetchShowViewers = async (showId: string) => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_SHOW_VIEWERS}`,
        { showId },
      );
    }
    const usersIndex = REACT_APP_ALGOLIA_USER_INDEX_NAME || '';
    await searchClient.clearCache();
    const index = searchClient.initIndex(usersIndex);

    return index.search('', {
      filters: `show_id:${showId} AND role_type:ShowViewer`,
      hitsPerPage: 10000,
    });
  } catch (error) {
    console.log('fetchShowViewers: show list fetch failed::', error);
  }
};

export const fetchStreamByShowName = async (showName: string) => {
  try {
    if (shouldUseProxy('algolia')) {
      return postProtectedAPI(
        `${REACT_APP_PROXY_GATEWAY_URL}${ALGOLIA_ENDPOINTS.FETCH_STREAM_BY_SHOW_NAME}`,
        { showName },
      );
    }

    const index = searchClient.initIndex(REACT_APP_ALGOLIA_STREAM_INDEX_NAME || '');
    const result = await index.search('', {
      filters: `show_name:"${showName}"`,
      hitsPerPage: 10000,
    });

    return result;
  } catch (error) {
    console.log('if show by id fetch failed::', error);
  }
};
